import {Controller} from '@hotwired/stimulus';

export class RadioToggleController extends Controller {

    static targets = ["element", "radio"]

    connect() {
        for (let element of this.elementTargets) {
            this.toggle(element, this.radioValue());
            this.radioTargets.forEach(r => r.addEventListener('change', event => {
                for (let element of this.elementTargets) {
                    this.toggle(element, this.radioValue());
                }
            }))
        }
    }

    radioValue() {
        return this.radioTargets.find(r => r.checked)?.value;
    }

    toggle(element, radioValue) {
        const visibleValues = element.dataset.values || element.dataset.radioToggleValues;
        if (element && visibleValues != undefined) {
            let hidden;
            if (visibleValues === '*') {
                hidden = !radioValue;
            } else {
                hidden = !visibleValues.split(',').includes(radioValue);
            }
            element.hidden = hidden;
            if (['INPUT', 'SELECT'].includes(element.tagName)) {
                element.disabled = hidden;
            }
        }
    }

}
