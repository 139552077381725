import { Application } from '@hotwired/stimulus'
import { DatepickerController } from '../controllers/datepicker.controller';
import '@hotwired/turbo-rails'
import { InvoiceItemSelectorController } from '../controllers/invoice-item-selector.controller';
import { RadioToggleController } from '../controllers/radio-toggle.controller';
import { StripePaymentController } from '../controllers/stripe-payment.controller';
import Reveal from 'stimulus-reveal-controller'
import {TimerController} from "../controllers/timer.controller";

require('jquery');
require('../components/jquery-ui')

// window.$ = jQuery;
// window.jQuery = jQuery;

require('@rails/ujs').start();
// require("@rails/activestorage").start();
require('../components/bootstrap');
require('../components/datepicker');
require('../components/select2');
require('../components/bootbox');
require('../components/student_portal_base.css')
require('font-awesome/css/font-awesome.min.css')

const application = Application.start();
application.register('datepicker', DatepickerController);
application.register('radio-toggle', RadioToggleController);
application.register('reveal', Reveal);
application.register('stripe-payment', StripePaymentController);
application.register('invoice-item-selector', InvoiceItemSelectorController)
application.register('timer', TimerController)

$(function () {
    activateSelect2(document);
});

function activateSelect2(elem) {
    $(elem).find('.select2').each(function (i, e) {
        var getUrl = function () {
            var url = select.data('source');
            var appendValue = select.data('source-append-value-of');
            if (appendValue) {
                var appendedValue = $(select.data('source-append-value-of')).val();
                var appendValueMissingMessage = select.data('source-append-value-of-missing-message');
                if (!appendedValue && appendValueMissingMessage) {
                    bootbox.alert(appendValueMissingMessage);
                    throw new Error('Missing value for get parameter');
                }
                url = url + $(select.data('source-append-value-of')).val();
            }
            return url;
        };

        var select = $(e);
        var options = {
            theme: 'bootstrap',
            allowClear: true,
            placeholder: '-'
        };
        if (select.hasClass('ajax')) {
            options.ajax = {
                url: getUrl,
                dataType: 'json',
                data: function (term, page) {
                    return { q: term, page: page, per: 30 };
                },
                results: function (data, page) {
                    return { results: data };
                }
            };
            // TODO is the following condition correct? should not be attribute 'class' but 'multiple'
            if (select.hasClass('multiple')) {
                options.multiple = true;
            }
        }
        // always keep open when able to select multiple
        if (select.attr('multiple') || select.hasClass('multiple')) {
            options.closeOnSelect = false;
        }
        if (select.hasClass('sortable')) {
            select.select2Sortable(options);
        } else {
            select.select2(options)
        }
        if (select.hasClass('ajax') && select.data('value-id')) {
            select.select2('data', { id: select.data('value-id'), text: select.data('value-name') });
        }
        if (select.hasClass('ajax') && select.data('values')) {
            select.select2('data', select.data('values'));
        }
        if (select.data('source-append-value-of')) {
            $(select.data('source-append-value-of')).change(function () {
                select.select2('val', '');
            });
        }
    });
}
