import { Controller } from '@hotwired/stimulus';

export class InvoiceItemSelectorController extends Controller {

    // checkbox dataset: amount, group, order, due

    static targets = ['checkbox', 'sum', 'submit', 'overdueItemsHint']

    static values = {
        currencySymbol: String
    }

    connect() {
        this.checkboxTargets.forEach(checkbox => checkbox.addEventListener('click', () => this.checkboxClick(checkbox)));
        this.updateSum();
    }

    checkboxClick(checkbox) {
        if (checkbox.checked) {
            this.checkboxTargets
                .filter((cb) => cb.dataset.group === checkbox.dataset.group && cb.dataset.order < checkbox.dataset.order)
                .forEach((cb) => cb.checked = true);
        } else {
            this.checkboxTargets
                .filter((cb) => cb.dataset.group === checkbox.dataset.group && cb.dataset.order > checkbox.dataset.order)
                .forEach((cb) => cb.checked = false);
        }
        this.updateSum();
    }

    updateSum() {
        const sum = this.checkboxTargets
            .filter(checkbox => checkbox.checked)
            .map(checkbox => parseFloat(checkbox.dataset.amount))
            .reduce(function (pv, cv) {
                return pv + cv;
            }, 0);
        this.submitTarget.disabled = sum === 0;
        this.counterAnim(parseFloat(this.sumTarget.dataset.value) || 0, sum)
        this.sumTarget.dataset.value = sum;
        this.overdueItemsHintTarget.hidden = this.checkboxTargets
            .every(checkbox => checkbox.checked || !checkbox.dataset.overdue)
    }

    localize(amount) {
        return amount.toLocaleString(document.documentElement.lang, { style: 'currency', currency: this.currencySymbolValue });
    }

    counterAnim = (start, end) => {
        let startTimestamp = null;
        const step = (timestamp) => {
            if (!startTimestamp) startTimestamp = timestamp;
            const progress = Math.min((timestamp - startTimestamp) / 200, 1);
            this.sumTarget.innerText = this.localize(Math.floor(progress * (end - start) + start));
            if (progress < 1) {
                window.requestAnimationFrame(step);
            } else {
                this.sumTarget.innerText = this.localize(end);
            }
        };
        window.requestAnimationFrame(step);
    };
}
