import {Controller} from '@hotwired/stimulus';

export class TimerController extends Controller {

    static targets = ['hideOnTimeout', 'showOnTimeout', 'countdown']

    static values = {
        time: Number
    }

    time = null;

    connect() {
        this.parseTime()
        if (this.time) {
            this.refresh()
        } else {
            this.showOnTimeoutTargets.forEach((e) => e.hidden = true);
            this.hideOnTimeoutTargets.forEach((e) => e.hidden = false);
        }
    }

    refresh() {
        this.draw();
        setTimeout(() => { this.refresh() }, 1000)
    }

    draw() {
        let timeDiff = this.time.getTime() - new Date().getTime();
        let timedOut = false;
        if (timeDiff < 0) {
            timeDiff = 0;
            timedOut = true;
        }
        const hours = Math.floor(timeDiff / (60 * 60 * 1000))
        const minutes = Math.floor((timeDiff % (60 * 60 * 1000)) / (60 * 1000))
        const seconds = Math.floor(((timeDiff % (60 * 60 * 1000)) % (60 * 1000)) / 1000)
        let result = '';
        if (hours > 0) {
            result = `${hours}:`
        }
        if (minutes < 10) {
            result = result + `0${minutes}:`
        } else {
            result = result + `${minutes}:`
        }
        if (seconds < 10) {
            result = result + `0${seconds}`
        } else {
            result = result + seconds
        }
        this.countdownTargets.forEach((e) => e.innerHTML = result);
        this.showOnTimeoutTargets.forEach((e) => e.hidden = !timedOut);
        this.hideOnTimeoutTargets.forEach((e) => {
            e.hidden = timedOut
            if (timedOut) {
                e.disabled = true;
            }
        });
    }

    parseTime() {
        if (!this.timeValue) {
            return
        }
        this.time = new Date(this.timeValue * 1000)
    }
}
