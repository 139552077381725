require('jquery-ui');
require('jquery-ui/themes/base/all.css');
require('jquery-ui/ui/core');
require('jquery-ui/ui/effect');
require('jquery-ui/ui/effects/effect-fade')
require('jquery-ui/ui/effects/effect-fade')
require('jquery-ui/ui/effects/effect-puff')
require('jquery-ui/ui/widgets/sortable');
require('jquery-ui/ui/widgets/draggable');

